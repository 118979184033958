import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Movies, Pagination } from "../components"

const VideosPage = ({ data, pageContext }) => {
  const {
    allContentfulVideo: { nodes: movies },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Movies title="Videók" movies={movies} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query ConcertsQuery($skip: Int, $limit: Int) {
    allContentfulVideo(
      limit: $limit
      skip: $skip
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        contentful_id
        videoTitle
        performer {
          performerName
        }
        videoText {
          childMarkdownRemark {
            html
            excerpt(format: HTML, pruneLength: 100)
          }
        }
        videoLink
        category {
          categoryName
        }
        videoImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default VideosPage
